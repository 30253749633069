<template>
    <div class="container-fluid px-proportal py-5">
        <p class="heading-m fw-bold mb-5 hstack gap-2">
            <span>Here's your schedule</span> 
            <a href="/scheduling/#/sc/appointments" class="subheading fw-bold text-info">View all</a>
        </p>
        <UpcomingAppointmentsListing></UpcomingAppointmentsListing>
    </div>
</template>

<script lang="ts" setup>
import { UpcomingAppointmentsListing } from './scheduling';

</script>

<style lang="scss" scoped>
</style>