<template>
    <div class="hstack mb-3">
        <div class="w-100">
            <div class="hstack mb-1">
                <p class="w-100 mb-0">
                    <slot name="label-start"></slot>
                    <span class="me-2">{{ label }} </span>
                    <span v-if="count !== undefined && count !== null" class="align-self-center badge bg-info bg-opacity-10 text-dark border analytics-count-badge">
                        {{ formatNumberWithCommas(count) }}
                    </span>
                    </p>
                <div style="min-width:60px;"></div>
            </div>
            <div class="hstack">
                <div class="progress w-100" role="progressbar" aria-label="Basic example" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
                    <div class="progress-bar" :style="{ width: percentage, backgroundColor: bgColor }"></div>
                </div>
                <div class="fw-bold col text-end" style="min-width:60px;">{{ percentage }}</div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { formatNumberWithCommas } from '@/modules/core/utilities';
defineProps({
    bgColor: {
        type: String,
        default: '#4C82F7'
    },
    percentage: {
        type: String,
        required: true
    },
    count: Number,
    label: {
        type: String,
        required: true
    }
})
</script>