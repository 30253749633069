<template>
    <Tabs id="pricing-table-tabs" class="pricing-table">
        <TabPane id="pricing-monthly-tab" title="Monthly">
            <div class="grid pricing-cards gap-md-1">
                <PricingCard 
                    v-for="(plan, i) in subscriptionPlans" 
                    :key="plan.key" 
                    :plan="plan"
                    interval="month"
                ></PricingCard>
            </div> 
        </TabPane>
        <TabPane id="pricing-annually-tab" title="Annually">
            <div class="grid pricing-cards gap-md-1">
                <PricingCard 
                    v-for="(plan, i) in subscriptionPlans" 
                    :key="plan.key" 
                    :plan="plan"
                    interval="year"
                ></PricingCard>
            </div>
        </TabPane>
    </Tabs>
</template>

<script lang="ts" setup>
import type { SubscriptionPlan } from '@/modules/proportal/types';
import { TabPane, Tabs } from '@/modules/core/components';
import { PricingCard } from '@/modules/proportal/components';

interface Props {
    subscriptionPlans: SubscriptionPlan[];
}

const props = defineProps<Props>();
</script>

<style lang="scss">
.pricing-table {
    border: 1px solid #E2E5F1;
    border-radius: 6px;
    width: max-content;
    margin-top: 3rem;
    margin-bottom: 2rem;
    margin-left: auto;
    margin-right: auto;
    background-color: $white;
    padding: 0.4rem;
    .nav-item {
        &:not(:last-child) {
            margin-right: 0;
        }
    }
    .nav-link {
        border-radius: 6px;
        color: #909090;
        padding: 0.4rem 1.2rem;
        @include media-breakpoint-up(lg) {
            padding: 0.65rem 1.5rem;
        }
        &:hover, &:focus {
            border-color: transparent;
        }
        &.active {
            background-color: $pink;
            color: $dark;
        }
    }
}
</style>